<script setup lang="ts">
defineProps<{
  tabs: string[]
}>()
defineSlots<{ default(p: { tabIndex: number }): any }>()

const tabIndex = ref(0)

const changeTabIndex = (index: number) => {
  tabIndex.value = index
}
</script>

<template>
  <div>
    <div
      class="tabs flex flew-nowrap tabs overflow-x-auto overflow-y-visible border-b border-gray-200 mb-6"
    >
      <button
        v-for="(tab, i) in tabs"
        :key="i"
        :class="[
          'pb-2 mr-9 text-base font-bold shrink-0 relative',
          {
            'text-gray-500': tabIndex !== i,
            'text-gray-700 border-b border-gray-700': tabIndex === i,
          },
        ]"
        type="button"
        @click="changeTabIndex(i)"
      >
        {{ tab }}
      </button>
    </div>

    <slot :tab-index="tabIndex" />
  </div>
</template>

<style>
.tabs::-webkit-scrollbar {
  display: none;
}
</style>
